import { LotType } from 'constants/general';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import { IAdditionalApiStatuses } from './slices/interfaces';
import { ILotCultureParameter } from './allReducer';
import {
  CLEAR_ONE_OFFER,
  GET_ONE_OFFER_START,
  GET_ONE_OFFER_SUCCESS,
  GET_ONE_OFFER_FAILURE,
} from '../actions/oneOfferActions';
import { Nullable } from 'core/interfaces/common.interfaces';

export interface ILotBase {
  id: number;
  type: LotType;
  basis_id: number;
  company_id: number;
  culture_id: number;
  currency_id: number;
  location_id?: number;
  origin_id: number;
  packaging_id: number;
  payment_id: number;
  shipping_id: number;

  volume: number;
  price: number;
  is_organic: boolean;
  created_at: string;
  updated_at: string;

  public: boolean;

  trades_count: number;
  is_favorite: boolean;
  is_my: boolean | null;
  is_my_company: boolean | null;
  is_active: boolean;

  owner: string;
  comment: string;
  trade: {
    id?: number;
    status: string | null; // TYPE: Change to enum
    offer_id?: number;
    request_id?: number;
  };
}

export interface ILot extends ILotBase {

  load_standard: number;
  number_of_containers: number;
  load_limitation: number;

  can_change_culture: boolean;
  is_trade_confirming: boolean;

  mandatory_documents: number[];
  optional_documents: number[];

  parameters: ILotCultureParameter[];

  location: {
    id: number;
    index: string;
    country_id: number;
    port_id?: Nullable<number>;
    state?: Nullable<string>;
    state_name?: string | null;
    district?: Nullable<string>;
    district_name?: string | null;
    city: string;
    station?: Nullable<string>;
    details?: Nullable<string>;
    archived_at: Nullable<string>;
    created_at: string;
  }

  overalls: {
    offer: {
      count: number | null;
      volume: number | null;
    },
    request: {
      count: number | null;
      volume: number | null;
    },
  },
}

export interface IOffer extends ILot {
  type: LotType.offer;

  date_of_manufacture: number;
  valid_until: string;
  images: { id: number; image: 'string'; }[];
}

const initialState: { item?: IOffer } & IAdditionalApiStatuses = {
  loading: false,
  loaded: false,
  error: null,
};


interface ActionSuccses {
  type: typeof GET_ONE_OFFER_SUCCESS;
  payload: IOffer;
}
interface ActionLoading {
  type: typeof GET_ONE_OFFER_START;
}
interface ActionFailing {
  type: typeof GET_ONE_OFFER_FAILURE;
  payload: { error: AnyTODO };
}
interface ActionClear {
  type: typeof CLEAR_ONE_OFFER;
  appending: boolean;
}

type Action = ActionSuccses | ActionLoading | ActionFailing | ActionClear;

export default function oneOfferReducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case GET_ONE_OFFER_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ONE_OFFER_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
        loaded: true,
        error: null,
      };
    case GET_ONE_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error,
      };
    case CLEAR_ONE_OFFER:
      return initialState;
    default:
      return state;
  }
}
