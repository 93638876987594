import store from 'store/store';
import Nav, { ROUTES } from 'nav';
import { ICompany } from 'reducers/allReducer';
import { generatePath } from 'react-router';

export type { ICompany };
export { Nav };

export class Company {
  protected _store!: ReturnType<typeof store['getState']>;

  static find(id: string | number): Company {
    return new this(this.store.all.companies.list[id] || {});
  }

  private static get store() {
    return store.getState();
  }

  constructor (
    public $: Partial<ICompany>,
  ) {}

  get id() { return this.$.id; }
  get name(): string { return this.$.name || ''; }
  get localName() { return this.$.local_name || this.name; }
  get abbr(): string {
    const str = (this.localName || 'NA');
    return (str.split(/[\s_!?.\-\\/]/).map(s => s[0]).join('') + str[1]).slice(0, 2);
  }

  get path(): string {
    return generatePath(ROUTES.companies.show.goods, { id: this.$.id || 0 });
  }

  goTo(e?: React.SyntheticEvent): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Nav.go(this.path);
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
