import { NOTIFICATION_TYPES } from 'modules/Notifications/NotificationsItem';
import { SCOPE_COMPANIES, SCOPE_COMPANY_HANDSHAKES } from '../Companies/Companies';

export const SCOPE_NOTIFICATIONS = 'Notifications';

export const uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    title: `$t(${SCOPE_COMPANIES}$${SCOPE_COMPANY_HANDSHAKES}$messages\${{type}})`,
    description: 'від {{company}}',
  },
};

export const en: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    title: `$t(${SCOPE_COMPANIES}$${SCOPE_COMPANY_HANDSHAKES}$messages\${{type}})`,
    description: 'from {{company}}',
  },
};

export const ru: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    title: `$t(${SCOPE_COMPANIES}$${SCOPE_COMPANY_HANDSHAKES}$messages\${{type}})`,
    description: 'от {{company}}',
  },
};

export const bg: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    title: `$t(${SCOPE_COMPANIES}$${SCOPE_COMPANY_HANDSHAKES}$messages\${{type}})`,
    description: 'от {{company}}',
  },
};
