import moment from 'moment';
import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  LOAD_MORE_NOTIFICATIONS,
  GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS,
  CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT,
} from 'actions/notificationsActions';
import { FormatEnum } from 'constants/general';
import { NOTIFICATION_TYPES } from 'modules/Notifications/NotificationsItem';

interface IData {
  type: NOTIFICATION_TYPES;
  item: NOTIFICATION_TYPES;
  Item: { id: number } & Record<string, string | number | null>;
  [key: string]: string | number | { id: number } & Record<string, string | number | null>;
}

export interface INotification {
  created_at: string;
  data: IData;
  description: string | null;
  id: number;
  level: string;
  recipient: number;
  title: string;
  unread: boolean;
}

export interface IListState<T> {
  loading: boolean;
  page: number;
  list: T[];
  noticesToday: T[];
  noticesOthers: T[];
  next?: null | string;
  previous?: null | string;
  error: null | string;
  count: number;
  unread_notifications: number;
  unread_trade_message: number;
}

const initialState: IListState<INotification> = {
  page: 0,
  list: [],
  noticesToday: [],
  noticesOthers: [],
  count: 0,
  next: null,
  previous: null,
  loading: false,
  error: null,
  unread_notifications: 0,
  unread_trade_message: 0,
};


interface ActionStart {
  type: typeof GET_NOTIFICATIONS_START;
  append: boolean;
}
interface ActionSuccess {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: Pick<IListState<INotification>, 'page' | 'count' | 'next' | 'list'>;
}
interface ActionLoadMore {
  type: typeof LOAD_MORE_NOTIFICATIONS;
  payload: Pick<IListState<INotification>, 'page' | 'count' | 'next' | 'list'>;
}
interface ActionCountNotification {
  type: typeof GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS;
  payload: { unread_notifications: number; unread_trade_message: number };
}
interface ActionNotificationFailure {
  type: typeof GET_NOTIFICATIONS_FAILURE;
  payload: { error: string };
}
interface ActionChatMessagesCount {
  type: typeof CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT;
  payload: { increment?: number; absolute?: number };
}

type Action = ActionStart | ActionSuccess | ActionLoadMore |
  ActionCountNotification | ActionNotificationFailure | ActionChatMessagesCount;


export default function notificationsReducer(state = initialState, action: Action): IListState<INotification> {
  switch (action.type) {
    case GET_NOTIFICATIONS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS: {
      const currentDate = moment(new Date()).format(FormatEnum.main);

      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,

        noticesToday: action.payload.list.filter(
          ({ created_at }) => moment(created_at).format(FormatEnum.main) === currentDate,
        ),

        noticesOthers: action.payload.list.filter(
          ({ created_at }) => moment(created_at).format(FormatEnum.main) !== currentDate,
        ),
      };
    }
    case LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        page: action.payload.page,
        loading: false,
        error: null,
        next: action.payload.next,
        list: [...state.list, ...action.payload.list],
        count: action.payload.count,
        noticesToday: (() => {
          const currentDate = moment(new Date());
          return [...state.list, ...action.payload.list].filter(
            ({ created_at }) => moment(created_at).format(FormatEnum.main) === currentDate.format(FormatEnum.main),
          );
        })(),
        noticesOthers: (() => {
          const currentDate = moment(new Date());
          return [...state.list, ...action.payload.list].filter(
            ({ created_at }) => currentDate.format(FormatEnum.main) !== moment(created_at).format(FormatEnum.main),
          );
        })(),
      };
    case GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT:
      return {
        ...state,
        unread_trade_message: action.payload.absolute
          ? action.payload.absolute
          : Math.max(0, state.unread_trade_message + (action.payload.increment || 0)),
      };
    default:
      return state;
  }
}
