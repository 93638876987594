import { useMemo, useRef } from 'react';
import { useEventListener } from './useEventListener';


interface IHotkeyConfig {
  keyCode: number;
  shiftKey?: boolean;
  altKey?: boolean;
  ctrlKey?: boolean;
  call: (event?: KeyboardEvent) => unknown;
  stopPropagation?: boolean | (() => boolean);
  off?: boolean;
}

const FIELDS = ['keyCode', 'ctrlKey', 'altKey', 'shiftKey'] as const;

function executor(event: KeyboardEvent, hotkeys: IHotkeyConfig[]) {
  if (!event) return;
  const hotkey: IHotkeyConfig | undefined = hotkeys.find(hotkey =>
    FIELDS.every(field => (hotkey[field] || false) === event[field]),
  );
  if (!hotkey || hotkey.off) return;
  if (['INPUT', 'TEXTAREA'].includes((event.target as HTMLElement)?.tagName)) return;
  hotkey.call?.(event);
  const { stopPropagation = true } = hotkey;
  if (stopPropagation instanceof Function ? stopPropagation() : stopPropagation) {
    event.stopImmediatePropagation();
  }
  event.preventDefault();
}

export function useHotkeys(hotkeys: IHotkeyConfig[], element: HTMLElement | Window = window){
  const refArr = useRef<IHotkeyConfig[]>(hotkeys);
  refArr.current = hotkeys; // Update config without reinit events
  const memoExecuter = useMemo<(e: KeyboardEvent) => void>(() => e => executor(e, refArr.current), []);
  useEventListener('keydown', memoExecuter, element);
}


export const HOTKEYS: Record<string, number> = {
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  O: 79,
  P: 80,
  R: 82,
  ESC: 27,
  LEFT: 37,
  DOWN: 40,
  RIGHT: 39,
  UP: 38,
};
