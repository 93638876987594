import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import { uk } from './uk';
import { en } from './en';
import { bg } from './bg';
import { ru } from './ru';
import * as common from './_single/common';

export enum LanguageEnum {
  uk = 'uk',
  en = 'en',
  bg = 'bg',
  ru = 'ru',
}

// export type LanguageType = `${LanguageEnum}`;
export type LanguagePage = Record<LanguageEnum, React.FC>

export class Language {
  static items: Language[] = [];
  static _default: Language;
  static list: Record<LanguageEnum, Language>;
  key: LanguageEnum;
  title: string;
  titleShort: string;
  path: string;
  static findByPath(path?: string): Language | undefined {
    return this.items.find((language) => language.path === path);
  }
  static findByPathOrDefault(path?: string): Language {
    return this.items.find((language) => language.path === path)
      || this.list[i18n.language as LanguageEnum]
      || this.default;
  }
  static get current(): Language {
    return this.list[i18n.language as LanguageEnum] || this.default;
  }
  static get default(): Language {
    return this.list[i18n.language as LanguageEnum] || this._default;
  }
  static isThen(isLang: LanguageEnum, thenLang: LanguageEnum, elseLang: LanguageEnum): Language {
    return this.list[this.current.key === isLang ? thenLang : elseLang];
  }
  static getPage(languagePage: LanguagePage): React.FC {
    return languagePage[this.current.key];
  }


  constructor(key: LanguageEnum, title: string, titleShort: string, path: string, isDefault = false) {
    this.key = key;
    this.title = title;
    this.titleShort = titleShort;
    this.path = path;
    Language.items.push(this);
    if (isDefault) {
      Language._default = this;
    }
  }

  get isCurrent(): boolean {
    return i18n.language === this.key;
  }

  fullPath(path: string): string {
    return `${process.env.REACT_APP_SITE_URL}/${this.path}${path}`;
  }
}

Language.list = {
  [LanguageEnum.en]: new Language(LanguageEnum.en, 'English', 'EN', 'en'),
  [LanguageEnum.uk]: new Language(LanguageEnum.uk, 'Українська', 'UA', 'ua', true),
  [LanguageEnum.bg]: new Language(LanguageEnum.bg, 'Български', 'BG', 'bg'),
  [LanguageEnum.ru]: new Language(LanguageEnum.ru, 'Русский', 'RU', 'ru'),
};



const LOCAL_NAMESPACES: string[] = [
  // 'autocomplete',
  'Chat',
  'Dashboard',
  'Invite',
  'Landing',
  'MetaTag',
  'filters',
  'forms',
  'multiSelect',
  'Settings',
  'Trades',
  '_single/emptyPage',
  // '_single/common',
  '_single/RequestLabel',
  '_single/Logo',
  '_single/Lot',
];

const combine: Partial<Record<LanguageEnum, AnyTODO>> = {};
LOCAL_NAMESPACES.forEach(path => {
  const name = path.match(/[^/]*$/)?.[0];
  if (!name) return;
  Object.values(LanguageEnum).forEach(language => {
    combine[language] = combine[language] || {};
    const req =  require(`./${path}`);
    combine[language][name] = req[language] || req.default[language];
  });
});
const req = (require as AnyTODO).context('./structure', true, /\.ts$/);
req.keys().map((file: AnyTODO) => {
  const field = file.match(/^\..*\/([^/].*)\.ts$/)[1];
  const reqFile = req(file);
  Object.values(LanguageEnum).forEach(language => combine[language][field] = reqFile[language]);
});


const resources = {
  uk: {
    translation: {
      ...uk,
      ...common.uk,
      ...combine.uk,
    },
  },
  en: {
    translation: {
      ...en,
      ...common.en,
      ...combine.en,
    },
  },
  bg: {
    translation: {
      ...bg,
      ...common.bg,
      ...combine.bg,
    },
  },
  ru: {
    translation: {
      ...ru,
      ...common.ru,
      ...combine.ru,
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources,
    // load: 'unspecific',
    supportedLngs: Object.values(LanguageEnum),
    fallbackLng: [LanguageEnum.en],
    nsSeparator: '::',
    keySeparator: '$',
    interpolation: {
      // skipOnVariables: false,
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        switch (format) {
          case 'uppercase':
            return `${value}`.toUpperCase();
          case 'capitalize': {
            const newValue = `${value}`;
            return `${newValue.charAt(0).toUpperCase()}${newValue.slice(1)}`;
          }
          default:
            // eslint-disable-next-line no-console
            console.error('wrong format', value, format);
            return value;
        }
      },
    },
    fallbackNS: 'common',
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      convertDetectedLanguage: (lng: string) => {
        return lng === 'ua' ? LanguageEnum.uk : lng;
      },
    },
    // react: {
    //   transSupportBasicHtmlNodes: true,
    //   transKeepBasicHtmlNodesFor: ['br', 'i', 'p', 'input'],
    // },
  }, undefined);


import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { SCOPE_FORMS_ERROR } from './forms/errors';

declare module 'yup' {
  interface StringSchema <
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    phone(): StringSchema<TType, TContext, TOut>;
    emailOrPhone(): StringSchema<TType, TContext, TOut>;
  }
}


function t(scopedKey: string | string[], opt?: Parameters<typeof i18n['t']>[2]): string {
  return i18n.t([SCOPE_FORMS_ERROR, scopedKey].flat().filter(Boolean).join('$'), opt);
}

Yup.setLocale({
  mixed: {
    required: () => t(['mixed', 'requred']),
  },
  string: {
    min: ({ min }) => t(['string', 'min'], { min }),
    max: ({ max }) => t(['string', 'max'], { max }),
    email: () => t(['string', 'email']),
  },
  array: {
    min: () => t(['mixed', 'requred']),
  },
  number: {
    min: ({ min }) => t(['number', 'min'], { min }),
    max: ({ max }) => t(['number', 'max'], { max }),
    moreThan: ({ more }) => t(['number', 'moreThan'], { more }),
  },
});

import { isValidNumber } from 'libphonenumber-js';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phone', function (message) {
  return this.test(
    'test-phone',
    message || t(['string', 'phone']),
    value => !!value && isValidNumber(value),
  );
});
Yup.addMethod<Yup.StringSchema>(Yup.string, 'emailOrPhone', function (message) {
  return this.test(
    'test-email-or-phone',
    message || t(['string', 'emailOrPhone']),
    value => !!value && (isValidNumber(value) || Yup.string().email().isValidSync(value)),
  );
});

export default i18n;
