export const SCOPE_COMPANIES = 'Companies';
export const SCOPE_COMPANY_HANDSHAKES = 'CompanyHandshake';

export const uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    button: {
      titles: {
        my: 'Статус мого рукотискання',
        opposite: 'Статус рукотискання від компанії {{company}}',
      },
      names: {
        on: 'Пожато',
        off: 'Віджато',
        deny: 'Заборонено',
      },
    },
    filter: {
      names: {
        all_hand: 'Всі',
        full: 'Прийняті',
        my: 'Відправлені',
        target: 'Отримані',
        any_deny: 'Відхилені',
      },
    },
    messages: {
      off: 'Рукостискання відкликане',
      on: 'Рукостискання встановлено',
      deny: 'Встановлено заборону рукостискань',
    },
  },
  CompanyReviewsCreate: {
    title: 'Відгук о {{name}}',
  },
  fields: {
    name: {
      title: 'Ім\'я',
    },
    description: {
      title: 'Опис',
    },
    country: {
      title: 'Країна',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Зв\'язок',
    },
  },
  show: {
    info: {
      created_date: 'на сервісі {{date}}',
      amount_of_workers: '{{amount}} осіб',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органіка',
    },
    about_us: 'Про нас',
    specialization: 'Спеціалізація',
    images: 'Фото',
    files: 'Документи',
    tabs: {
      goods: 'Товари',
      contacts: 'Контакти',
      reviews: 'Відгуки',
      history: 'Історія',
    },
  },
};

export const en: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    button: {
      titles: {
        my: 'My handshake status',
        opposite: 'Handshake status of {{company}}',
      },
      names: {
        off: 'Handshake revoked',
        on: 'Handshake established',
        deny: 'Handshake ban established',
      },
    },
    filter: {
      names: {
        all_hand: 'All',
        full: 'Accepted',
        my: 'Sent',
        target: 'Received',
        any_deny: 'Rejected',
      },
    },
    messages: {
      off: '',
      on: '',
      deny: '',
    },
  },
  CompanyReviewsCreate: {
    title: 'Review of {{name}}',
  },
  fields: {
    name: {
      title: 'Name',
    },
    description: {
      title: 'Description',
    },
    country: {
      title: 'Country',
    },
    rating: {
      title: 'Rating',
    },
    handshake: {
      title: 'Contact',
    },
  },
  show: {
    info: {
      created_date: 'on service {{date}}',
      amount_of_workers: '{{amount}} people',
      annual_turnover: '{{turnover}} mln/usd',
      is_organic: 'organic',
    },
    about_us: 'About us',
    specialization: 'Specialization',
    images: 'Photos',
    files: 'Documents',
    tabs: {
      goods: 'Goods',
      contacts: 'Contacts',
      reviews: 'Reviews',
      history: 'History',
    },
  },
};

export const ru: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    button: {
      titles: {
        my: 'Статус моего рукопожатия',
        opposite: 'Статус рукопожатия компании от {{company}}',
      },
      names: {
        on: 'Пожато',
        off: 'Отжато',
        deny: 'Запрещено',
      },
    },
    filter: {
      names: {
        all_hand: 'Все',
        full: 'Принятые',
        my: 'Отправленые',
        target: 'Полученые',
        any_deny: 'Отклоненые',
      },
    },
    messages: {
      off: '',
      on: '',
      deny: '',
    },
  },
  CompanyReviewsCreate: {
    title: 'Review {{name}}',
  },
  fields: {
    name: {
      title: 'Имя',
    },
    description: {
      title: 'Описание',
    },
    country: {
      title: 'Страна',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Связь',
    },
  },
  show: {
    info: {
      created_date: 'на сервисе {{date}}',
      amount_of_workers: '{{amount}} человек',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органика',
    },
    about_us: 'О нас',
    specialization: 'Специализация',
    images: 'Фото',
    files: 'Документы',
    tabs: {
      goods: 'Товары',
      contacts: 'Контакты',
      reviews: 'Отзывы',
      history: 'История',
    },
  },
};

export const bg: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    button: {
      titles: {
        my: 'Моето състояние на ръкостискане',
        opposite: 'Състояние на ръкостискане от {{компания}}',
      },
      names: {
        on: 'Пожато',
        off: 'Отжато',
        deny: 'Ограден',
      },
    },
    filter: {
      names: {
        all_hand: 'Всички',
        full: 'Прието',
        my: 'Изпратено',
        target: 'Получено',
        any_deny: 'Отхвърлено',
      },
    },
    messages: {
      off: 'Ръкостискането е отменено',
      on: 'Ръкостискането е установено',
      deny: 'Ръкостисканията са забранени',
    },
  },
  CompanyReviewsCreate: {
    title: 'Преглед на {{name}}',
  },
  fields: {
    name: {
      title: 'Име',
    },
    description: {
      title: 'Описание',
    },
    country: {
      title: 'държава',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Връзка',
    },
  },
  show: {
    info: {
      created_date: 'в услугата {{date}}',
      amount_of_workers: '{{amount}} души',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органика',
    },
    about_us: 'За нас',
    specialization: 'Специализация',
    images: 'Снимка',
    files: 'Документи',
    tabs: {
      goods: 'Стоки',
      contacts: 'Контакти',
      reviews: 'Рецензии',
      history: 'История',
    },
  },
};
