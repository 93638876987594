export const SCOPE_META_TAG = 'MetaTag';


export const uk = {
  home: {
    title: 'Перший український агромаркетплейс | glendeal.com',
    description: 'Агроплатформа Гленділ - ефективний інструмент для торгівлі агрокультурами. Ми пропонуємо легкий експорт продукції до світового ринку та актуальні пропозиції. Знаходьте покупців і продавайте урожай з легкістю!',
  },
  lots: {
    title: 'Купівля та продаж агрокультур | glendeal.com',
    description: 'Перегляньте поточні заявки на Гленділ для купівлі та продажу агрокультур оптом. Надійний експорт за кордон та по всій Україні. Залиште заявку вже зараз та отримайте найкращі пропозиції!',
  },
  lots_requests: {
    title: 'Продати зерно, боби та інші агрокультури | glendeal.com',
    description: 'Поточні заявки від покупців для продажу оптом зернових, бобових та інших агрокультур. Надійний експорт за кордон та по всій Україні. Залиште заявку вже зараз та отримайте найкращі пропозиції!',
  },
  lots_offers: {
    title: 'Купити зерно, боби та інші агрокультури | glendeal.com',
    description: 'Перегляньте поточні заявки на Glendeal для продажу зернових, бобових та інших агрокультур. Надійний експорт за кордон та по всій Україні. Залиште заявку вже зараз та отримайте найкращі пропозиції!',
  },
  lot_request: {
    title: 'Продати {{culutre}} №{{id}} від {{createdAt}} | glendeal.com',
    description: 'Заявка від покупця {{company}} на покупку {{culture}}, {{volume}} тонн, ціна/тонна {{price}}. Доставка в {{addressCountry}}, {{address}}. {{payment}}, період відвантаження {{dateValid}}',
  },
  lot_offer: {
    title: 'Купити {{culture}} №{{id}} від {{createdAt}} | glendeal.com',
    description: 'Заявка від продавця {{company}} на продаж {{culture}}, {{volume}} тонн, ціна/тонна {{price}}. Відвантаження з {{addressCountry}}, {{address}}. {{payment}}, заявка дійсна до {{dateValid}}',
  },
  market: {
    title: 'Ринок агрокультур | glendeal.com',
    description: 'Пропозиції на купівлю сільськогосподарської продукції українських та іноземних компаній. Надійний експорт за кордон та по всій Україні. Оптові ціни на зерно, пшеницю, кукурудзу, соняшник та інші агрокультури',
  },
  market_category: {
    title: '{{name}}: Купити, Продаж, Ціна в Україні | glendeal.com',
    description: 'Купити або продати оптом {{name}} від українських та іноземних компаній. Надійний експорт {{name}} за кордон з України. Оптові, закупівельні ціни на платформі для продажу {{name}} Glendeal',
  },
  farmers: {
    title: 'Платформа для фермерів з продажу та купівлі агрокультур | glendeal.com',
    description: 'Гленділ - найкраща агроплатформа з купівлі та продажу агрокультур для фермерів. Підвищте ефективність вашого сільського господарства з нашими послугами та рішеннями!',
  },
  brokers: {
    title: 'Платформа для брокерів з продажу та купівлі агрокультур | glendeal.com',
    description: 'Glendeal - найкраща агроплатформа з купівлі та продажу агрокультур. Найкращі умови для трейдерів, брокерів та посередників та найвигідніші угоди по всьому світу!',
  },
  buyers: {
    title: 'Агроплатформа з продажу та купівлі агрокультур | glendeal.com',
    description: 'Гленділ - найкраща агроплатформа з купівлі та продажу агрокультур. Найвигідніші пропозиції та конкурентні ціни від фермерів по всій Україні!',
  },
  contacts: {
    title: 'Контакти | glendeal.com',
    description: 'Зв\'яжіться з нами, щоб отримати консультацію щодо продажу або покупки агрокультур. Наші менеджери допоможуть знайти найкраще рішення для вас',
  },
  about: {
    title: 'Про нас | glendeal.com',
    description: 'Гленділ - український маркетплейс, який став результатом накопиченого 15-річного досвіду роботи. Відкрийте для себе торгівлю з нового боку, де купувати та продати стає простіше, цікавіше та надійніше',
  },
  public_agreement: {
    title: 'Публічна угода | glendeal.com',
    description: 'Ознайомтеся з публічним договором про умови співпраці, права та обов\'язки сторін на платформі Гленділ',
  },
  privacy_policy: {
    title: 'Політика конфіденційності | glendeal.com',
    description: 'Ознайомтеся з нашою політикою конфіденційності, де ми забезпечуємо захист особистих даних наших користувачів. Ваша приватність - наш пріоритет',
  },
  categories: {
    title: '{{name}}: Купити та продати гуртом | glendeal.com',
    description: '{{name}} - Купити та продати: Оголошення з купівлі та продажу по всій Україні та за кордоном ✓ Широкий асортимент ✓ Вигідні ціни',
  },
  cultures: {
    title: '{{name}}: Купити, Продаж, Ціна в Україні | glendeal.com',
    description: 'Купити або продати оптом {{name}} від українських та іноземних компаній. Надійний експорт {{name}} за кордон з України. Оптові, закупівельні ціни на платформі для продажу {{name}} Glendeal',
  },
};

export const en: typeof uk = {
  home: {
    title: 'The first Ukrainian agromarketplace | glendeal.com',
    description: 'The Glendeal agro-platform is an effective tool for trading crops. We offer easy export of products to the global market and up-to-date offers. Find buyers and sell your crops with ease!',
  },
  lots: {
    title: 'Buying and selling crops | glendeal.com',
    description: 'View current offers on Glendeal to buy and sell crops in bulk. Reliable exports abroad and throughout Ukraine. Leave a request now and get the best deals!',
  },
  lots_requests: {
    title: 'Sell grain, beans and other crops | glendeal.com',
    description: 'Current applications from buyers for the sale of grain, beans and other agricultural crops in bulk. Reliable exports abroad and throughout Ukraine. Leave a request now and get the best deals!',
  },
  lots_offers: {
    title: 'Buy grain, beans and other crops | glendeal.com',
    description: 'View current offers on Glendeal for the sale of grains, pulses and other agricultural crops. Reliable exports abroad and throughout Ukraine. Leave a request now and get the best deals!',
  },
  lot_request: {
    title: 'Sell {{culture}} #{{id}} from {{createdAt}} | glendeal.com',
    description: 'Bid from the buyer {{company}} for the purchase of {{culture}}, {{volume}} tons, price/ton {{price}}. Delivery to {{addressCountry}}, {{address}}. {{payment}}, shipment period {{dateValid}}',
  },
  lot_offer: {
    title: 'Buy {{culture}} #{{id}} from {{createdAt}} | glendeal.com',
    description: 'Bid from the seller {{company}} for sale {{culture}}, {{volume}} tons, price/ton {{price}}. Shipment from {{addressCountry}}, {{address}}. {{payment}}, the application is valid until {{dateValid}}',
  },
  market: {
    title: 'Agricultural crops market | glendeal.com',
    description: 'Offers for the purchase of agricultural products from Ukrainian and foreign companies. Reliable exports abroad and throughout Ukraine. Wholesale prices for grain, wheat, corn, sunflower and other crops',
  },
  market_category: {
    title: '{{name}}: Buy, Sell, Price in Ukraine | glendeal.com',
    description: 'Buy or sell in bulk {{name}} from Ukrainian and foreign companies. Reliable export of {{name}} abroad from Ukraine. Wholesale, purchase prices on the platform for the sale of {{name}} Glendeal',
  },
  farmers: {
    title: 'Platform for farmers to buy and sell agro crops | glendeal.com',
    description: 'Glendeal is the best agro platform for farmers to buy and sell agro crops. Increase your farming efficiency with our services and solutions!',
  },
  brokers: {
    title: 'Platform for brokers to buy and sell crops | glendeal.com',
    description: 'Glendeal is the best agricultural platform for buying and selling crops. The best conditions for traders, brokers and intermediaries and the most profitable deals around the world!',
  },
  buyers: {
    title: 'Agro platform for buying and selling agro crops | glendeal.com',
    description: 'Glendeal - the best agro platform for buying and selling crops. The most favorable offers and competitive prices from farmers all over Ukraine!',
  },
  contacts: {
    title: 'Contacts | glendeal.com',
    description: 'Contact us for advice on selling or buying agricultural crops. Our managers will help you find the best solution',
  },
  about: {
    title: 'About us | glendeal.com',
    description: 'Glendeal - Ukrainian marketplace, which is the result of accumulated 15 years of experience. Discover trading from a new side, where buying and selling becomes easier, more interesting and more reliable',
  },
  public_agreement: {
    title: 'Public agreement | glendeal.com',
    description: 'Read the public agreement on the terms of cooperation, rights and obligations of the parties on the Glendeal platform',
  },
  privacy_policy: {
    title: 'Privacy Policy | glendeal.com',
    description: 'Read our privacy policy where we protect our users\' personal information. Your privacy is our priority',
  },
  categories: {
    title: '{{name}}: Buy and sell in bulk | glendeal.com',
    description: '{{name}} - Buy and sell: Classifieds for buying and selling all over Ukraine and abroad ✓ Wide range of products ✓ Favorable prices',
  },
  cultures: {
    title: '{{name}}: Buy, Sell, Price in Ukraine | glendeal.com',
    description: 'Buy or sell in bulk {{name}} from Ukrainian and foreign companies. Reliable export of {{name}} abroad from Ukraine. Wholesale, purchase prices on the platform for selling {{name}} Glendeal',
  },
};

export const ru: typeof uk = {
  home: {
    title: 'Первый украинский агромаркетплейс | glendeal.com',
    description: 'Агроплатформа Глендил - эффективный инструмент для торговли агрокультурами. Мы предлагаем легкий экспорт продукции на мировой рынок и актуальные предложения. Находите покупателей и продавайте урожай с легкостью!',
  },
  lots: {
    title: 'Покупка и продажа агрокультур | glendeal.com',
    description: 'Просмотрите текущие заявки на Глендил для покупки и продажи агрокультур оптом. Надежный экспорт за границу и по всей Украине. Оставьте заявку уже сейчас и получите лучшие предложения!',
  },
  lots_requests: {
    title: 'Продать зерно, бобы и другие агрокультуры | glendeal.com',
    description: 'Текущие заявки от покупателей для продажи оптом зерновых, бобовых и других агрокультур. Надежный экспорт за границу и по всей Украине. Оставьте заявку уже сейчас и получите лучшие предложения!',
  },
  lots_offers: {
    title: 'Купить зерно, бобы и другие агрокультуры | glendeal.com',
    description: 'Просмотрите текущие заявки на Glendeal для продажи зерновых, бобовых и других агрокультур. Надежный экспорт за границу и по всей Украине. Оставьте заявку уже сейчас и получите лучшие предложения!',
  },
  lot_request: {
    title: 'Продать {{culture}} №{{id}} от {{createdAt}} | glendeal.com',
    description: 'Заявка от покупателя {{company}} на покупку {{culture}}, {{volume}} тонн, цена/тонна {{price}}. Доставка в {{addressCountry}}, {{address}}. {{payment}}, период отгрузки {{dateValid}}',
  },
  lot_offer: {
    title: 'Купить {{culture}} №{{id}} от {{createdAt}} | glendeal.com',
    description: 'Заявка от продавца {{company}} на продажу {{culture}}, {{volume}} тонн, цена/тонна {{price}}. Отгрузка из {{addressCountry}}, {{address}}. {{payment}}, заявка действительна до {{dateValid}}',
  },
  market: {
    title: 'Рынок агрокультур | glendeal.com',
    description: 'Предложения на покупку сельскохозяйственной продукции украинских и иностранных компаний. Надежный экспорт за границу и по всей Украине. Оптовые цены на зерно, пшеницу, кукурузу, подсолнечник и другие агрокультуры',
  },
  market_category: {
    title: '{{name}}: Купить, Продажа, Цена в Украине | glendeal.com',
    description: 'Купить или продать оптом {{name}} от украинских и иностранных компаний. Надежный экспорт {{name}} за границу из Украины. Оптовые, закупочные цены на платформе для продажи {{name}} Glendeal',
  },
  farmers: {
    title: 'Платформа для фермеров по продаже и покупке агрокультур | glendeal.com',
    description: 'Глендил - лучшая агроплатформа по покупке и продаже агрокультур для фермеров. Повысьте эффективность вашего сельского хозяйства с нашими услугами и решениями!',
  },
  brokers: {
    title: 'Платформа для брокеров по продаже и покупке агрокультур | glendeal.com',
    description: 'Glendeal - лучшая агроплатформа по покупке и продаже агрокультур. Лучшие условия для трейдеров, брокеров и посредников и выгодные сделки по всему миру!',
  },
  buyers: {
    title: 'Агроплатформа по продаже и покупке агрокультур | glendeal.com',
    description: 'Глендил - лучшая агроплатформа по покупке и продаже агрокультур. Самые выгодные предложения и конкурентные цены от фермеров по всей Украине!',
  },
  contacts: {
    title: 'Контакты | glendeal.com',
    description: 'Свяжитесь с нами, чтобы получить консультацию по продаже или покупке агрокультур. Наши менеджеры помогут найти наилучшее решение для вас',
  },
  about: {
    title: 'О нас | glendeal.com',
    description: 'Глендил - украинский маркетплейс, который стал результатом накопленного 15-летнего опыта работы. Откройте для себя торговлю с новой стороны, где покупать и продать становится проще, интереснее и надежнее',
  },
  public_agreement: {
    title: 'Публичное соглашение | glendeal.com',
    description: 'Ознакомьтесь с публичным договором об условиях сотрудничества, правах и обязанностях сторон на платформе Глендил',
  },
  privacy_policy: {
    title: 'Политика конфиденциальности | glendeal.com',
    description: 'Ознакомьтесь с нашей политикой конфиденциальности, где мы обеспечиваем защиту личных данных наших пользователей. Ваша приватность - наш приоритет',
  },
  categories: {
    title: '{{name}}: Купить и продать оптом | glendeal.com',
    description: '{{name}} - Купить и продать: Объявления по купле и продаже по всей Украине и за рубежом ✓ Широкий ассортимент ✓ Выгодные цены',
  },
  cultures: {
    title: '{{name}}: Купить, Продажа, Цена в Украине | glendeal.com',
    description: 'Купить или продать оптом {{name}} от украинских и иностранных компаний. Надежный экспорт {{name}} за границу из Украины. Оптовые, закупочные цены на платформе для продажи {{name}} Glendeal',
  },
};

export const bg: typeof uk = {
  home: {
    title: 'Първият украински агропазар | glendeal.com',
    description: 'Агроплатформата Glendeal е ефективен инструмент за търговия с агрокултури. Ние предлагаме лесен износ на продукти на световния пазар и актуални оферти. Намерете купувачи и продайте културите си с лекота!',
  },
  lots: {
    title: 'Купуване и продажба на агрокултури | glendeal.com',
    description: 'Прегледайте текущите оферти на Glendeal за покупка и продажба на зърнени култури на едро. Надежден износ в чужбина и в цяла Украйна. Оставете заявка сега и получете най-добрите оферти!',
  },
  lots_requests: {
    title: 'Продажба на зърно, боб и други култури | glendeal.com',
    description: 'Актуални заявки от купувачи за продажба на зърно, бобови култури и други селскостопански култури в насипно състояние. Надежден износ в чужбина и в цяла Украйна. Оставете заявка сега и получете най-добрите оферти!',
  },
  lots_offers: {
    title: 'Купуване на зърно, бобови и други култури | glendeal.com',
    description: 'Прегледайте текущите оферти на Glendeal за продажба на зърно, бобови и други култури. Надежден износ в чужбина и в цяла Украйна. Оставете заявка сега и получете най-добрите оферти!',
  },
  lot_request: {
    title: 'Продажба на {{culture}} №{{id}} от {{createdAt}} | glendeal.com',
    description: 'Заявка от купувача {{company}} за закупуване на {{culture}}, {{volume}} тона, цена/тон {{price}}. Доставка до {{addressCountry}}, {{address}}. {{payment}}, период на доставка {{dateValid}}',
  },
  lot_offer: {
    title: 'Купете {{culture}} №{{id}} от {{createdAt}} | glendeal.com',
    description: 'Оферта от продавача {{company}} за продажба на {{culture}}, {{volume}} тона, цена/тон {{price}}. Доставката е от {{addressCountry}}, {{address}}. {{payment}}, заявката е валидна до {{dateValid}}',
  },
  market: {
    title: 'Пазар на земеделски култури | glendeal.com',
    description: 'Оферти за закупуване на селскостопански продукти на украински и чуждестранни компании. Надежден износ в чужбина и в цяла Украйна. Цени на едро за зърно, пшеница, царевица, слънчоглед и други култури',
  },
  market_category: {
    title: '{{name}}: Покупка, продажба, цена в Украйна | glendeal.com',
    description: 'Купете или продайте насипно {{name}} от украински и чуждестранни компании. Надежден износ на {{name}} от Украйна в чужбина. Цени на едро, покупко-продажба в платформата за продажба на {{name}} Glendeal',
  },
  farmers: {
    title: 'Платформа за земеделски производители за покупка и продажба на агрокултури | glendeal.com',
    description: 'Glendeal е най-добрата селскостопанска платформа за покупка и продажба на земеделски култури за земеделските производители. Повишете ефективността на земеделието си с нашите услуги и решения!',
  },
  brokers: {
    title: 'Платформа за брокери за покупка и продажба на култури | glendeal.com',
    description: 'Glendeal е най-добрата селскостопанска платформа за покупка и продажба на култури. Най-добрите условия за търговци, брокери и посредници и най-изгодните сделки в целия свят!',
  },
  buyers: {
    title: 'Агроплатформа за покупка и продажба на култури | glendeal.com',
    description: 'Glendeal е най-добрата селскостопанска платформа за покупка и продажба на култури. Най-добрите оферти и конкурентни цени от земеделски производители от цяла Украйна!',
  },
  contacts: {
    title: 'Контакт | glendeal.com',
    description: 'Свържете се с нас, за да получите най-добрата поддръжка и съвети за нашите услуги. Нашите данни за Свържете се с нас, за да получите съвет за продажба или покупка на култури. Нашите мениджъри ще ви помогнат да намерите най-доброто решение за вас',
  },
  about: {
    title: 'За нас | glendeal.com',
    description: 'Glendeal е украински пазар, който е резултат от 15-годишен опит. Открийте търговията от нова страна, където купуването и продаването стават по-лесни, по-интересни и по-надеждни',
  },
  public_agreement: {
    title: 'Публично споразумение | glendeal.com',
    description: 'Прочетете публичното споразумение за условията на сътрудничество, правата и задълженията на страните в платформата Glendeal',
  },
  privacy_policy: {
    title: 'Политика за поверителност | glendeal.com',
    description: 'Прочетете нашата политика за поверителност, в която защитаваме личните данни на нашите потребители. Вашата поверителност е наш приоритет',
  },
  categories: {
    title: '{{name}}: Покупка и продаване на едро | glendeal.com',
    description: '{{name}} - Покупка и продаване: Обяви за купуване и продаване по цяла Украйна и зад граница ✓ Широк асортимент ✓ Изгодни цени',
  },
  cultures: {
    title: '{{name}}: Покупка, продажба, цена в Украйна | glendeal.com',
    description: 'Купувайте или продавайте насипно {{name}} от украински и чуждестранни компании. Надежден износ на {{name}} в чужбина от Украйна. Цени на едро, покупко-продажба в платформата за продажба на {{name}} Glendeal',
  },
};
