import { LotType } from 'constants/general';

export const SCOPE_CHAT = 'Chat';

export const uk = {
  tradeTypes: {
    softOffer: 'Ціна продажу',
    softRequest: 'Ціна закупівлі',
    hardOffer: 'Тверда пропозиція продажу',
    hardRequest: 'Тверда пропозиція закупівлі',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) від {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) від {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) від {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) від {{company}}`,
    hothing: '{{company}}',
    priceConfirmed: 'Ціна підтверджена',
    businessConfirmationBy: 'Бізнес підтвердження від {{company}}',
    closedBy: 'Закрито, {{company}}',
    buttons: {
      offer_price: 'Запропонувати ціну',
      accept_offer: 'Прийняти пропозицію',
      leave_trade: 'Вийти з торгів',
    },
    types: {
      confirmation: 'Підтвердження',
      trade_closed: '',
      bid_changed: 'Зміна ставки',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Нічого відправляти',
      errorSend: 'Не вдалося відправити повідомлення',
      exceedLength: 'Перевищено ліміт сімвлов ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Відправити повідомлення',
    },
  },
  SuggestPrice: {
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    agreedWithHardBid: 'Я розумію, що таке Тверді ставки:',
    softBidInfo: `
      <1>
        Цей вид ставки є price для іншої сторони.
      </1>
      <2>
        Зустріч може її підтвердити і тим самим зробити її Хард ставкою. Перебити Хард ставку можна буде теж тільки Хард.
      </2>
    `,
    hardBidInfo: `
      <1>
        Цей вид ставки може бути активним лише в одному з Трейдів за поточною заявкою. Це означає, що в інших Трейдах заявки ви не зможете ставити ставки.
      </1>
      <2>
        Ставка стане не акутальною, якщо закінчиться термін її дії (і тоді весь Трейд закриється), або якщо зустрічна сторона переб'є своєю Хард ставкою.
      </2>
      <3>
        Якщо зустрічна сторона підтвердить цю Хард ставку, то торги вважатимуться успішними, і це ініціює створення Контракту.
      </3>
    `,
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Останнє повідомлення',
      messagesCount: 'Загальна кількість повідомлень',
      unreadCount: 'Непрочитані повідомлення',
    },
  },
};

export const en: typeof uk = {
  tradeTypes: {
    softOffer: 'Seller\'s price',
    softRequest: 'Buyer\'s price',
    hardOffer: 'Seller\'s offer',
    hardRequest: 'Buyer\'s bid',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) from {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) from {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) from {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) from {{company}}`,
    hothing: '{{company}}',
    priceConfirmed: 'Цена подтверджена',
    businessConfirmationBy: 'Business confirmation from {{company}}',
    closedBy: 'Closed by {{company}}',
    buttons: {
      offer_price: 'Offer price',
      accept_offer: 'Accept offer',
      leave_trade: 'Leave trade',
    },
    types: {
      confirmation: 'confirmation',
      trade_closed: '',
      bid_changed: 'bid_changed',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Nothing to send',
      errorSend: 'Failed to send message',
      exceedLength: 'Exceeded character limit ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Send message',
    },
  },
  SuggestPrice: {
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    agreedWithHardBid: 'I understand what Hard Bid are:',
    softBidInfo: `
      <1>
        This type of price is informational for the other party.
      </1>
      <2>
        The other party can confirm it and thus make it a Hard Bid. A Hard bid can only be beaten by anothre Hard Bid.
      </2>
    `,
    hardBidInfo: `
      <1>
        This type of bid can be active only in one of the Trades for the current Request. This means that you will not be able to place bids in other Trades of the Request.
      </1>
      <2>
        The bid will become invalid if its validity period expires (and then the entire Trade will close), or if the opposite party outbids with its Hard Bid.
      </2>
      <3>
        If the opposite party confirms this Hard Bid, then the trade will be considered successful and this initiates the creation of a Contract.
      </3>
    `,
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Last message',
      messagesCount: 'Total number of messages',
      unreadCount: 'Unread messages',
    },
  },
};

export const ru: typeof uk = {
  tradeTypes: {
    softOffer: 'Цена продажи',
    softRequest: 'Цена закупки',
    hardOffer: 'Твёрдое предложение продажи',
    hardRequest: 'Твёрдое предложение закупки',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) от {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) от {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) от {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) от {{company}}`,
    hothing: '{{company}}',
    priceConfirmed: 'Цена подтверджена',
    businessConfirmationBy: 'Бизнес подтверждение от {{company}}',
    closedBy: 'Закрыто, {{company}}',
    buttons: {
      offer_price: 'Предложить цену',
      accept_offer: 'Принять предложение',
      leave_trade: 'Выйти из торгов',
    },
    types: {
      confirmation: 'Подтверждение',
      trade_closed: '',
      bid_changed: 'Изменение ставки',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Нечего отправлять',
      errorSend: 'Не удалось отправить сообщение',
      exceedLength: 'Превышен лимит симвлов ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Отправить сообщение',
    },
  },
  SuggestPrice: {
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    agreedWithHardBid: 'Я понимаю что такое Хард ставки:',
    softBidInfo: `
      <1>
        Данный вид цены является информационным для другой стороны.
      </1>
      <2>
        Встречная сторона может её подтвердить и тем самым сделать её Хард ставкой. Перебить Хард ставку можно будет только такой же Хард.
      </2>
    `,
    hardBidInfo: `
      <1>
        Данный вид ставки может быть активным только в одном из Трейдов по текущей Заявке. Это означает что в других Трейдах заявки вы не сможете ставить ставки.
      </1>
      <2>
        Ставка станет не акутальной если закончится срок её действия (и тогда весь Трейд закроется), либо если встречная сторона перебьёт своей Хард ставкой.
      </2>
      <3>
        Если встречная сторона подтвердит данную Хард ставку, то торги будут считаться успешными и это инициирует создание Контракта.
      </3>
    `,
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Последнее сообщение',
      messagesCount: 'Общее количество сообщений',
      unreadCount: 'Непрочитанные сообщения',
    },
  },
};

export const bg: typeof uk = {
  tradeTypes: {
    softOffer: 'Продажна цена',
    softRequest: 'Покупна цена',
    hardOffer: 'Твърда оферта за продажба',
    hardRequest: 'Твърда оферта за покупка',
  },
  Message: {
    softOffer: `$t(${SCOPE_CHAT}$tradeTypes$softOffer) от {{company}}`,
    hardOffer: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer) от {{company}}`,
    softRequest: `$t(${SCOPE_CHAT}$tradeTypes$softRequest) от {{company}}`,
    hardRequest: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest) от {{company}}`,
    hothing: '{{company}}',
    priceConfirmed: 'Цената е потвърдена',
    businessConfirmationBy: 'Бизнес потвърждение от {{company}}',
    closedBy: 'Закрито, {{company}}',
    buttons: {
      offer_price: 'Предложи цена',
      accept_offer: 'Приеми предложение',
      leave_trade: 'Излез от търговията',
    },
    types: {
      confirmation: 'Потвърждение',
      trade_closed: '',
      bid_changed: 'Промяна на офертата',
      trade_start: '',
    },
    infoPopups: {
      emptyMessage: 'Няма какво да изпратите',
      errorSend: 'Неуспешно изпращане на съобщението',
      exceedLength: 'Превишен лимит на символите ({{count}}/{{MAX_CHAT_MESSAGE_LENGTH}})',
    },
    placeholders: {
      send_message: 'Изпрати съобщение',
    },
  },
  SuggestPrice: {
    tabs: {
      [LotType.offer]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softOffer)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardOffer)`,
      },
      [LotType.request]: {
        soft_price: `$t(${SCOPE_CHAT}$tradeTypes$softRequest)`,
        hard_bid: `$t(${SCOPE_CHAT}$tradeTypes$hardRequest)`,
      },
    },
    agreedWithHardBid: 'Разбирам какво представляват Хард ставки:',
    softBidInfo: `
      <1>
        Този вид цени е информационен за отсрещната страна.
      </1>
      <2>
        Насрещната страна може да го потвърди и по този начин да го направи хард ставка. Ще бъде възможно да наддадете Хард ставк само с Хард.
      </2>
    `,
    hardBidInfo: `
      <1>
        Този тип залог може да бъде активен само в една от сделките за текущата поръчка. Това означава, че няма да можете да наддавате в други сделки с приложения.
      </1>
      <2>
        Залогът ще стане неуместен, ако срокът му на валидност изтече (и тогава цялата сделка ще бъде затворена) или ако противниковата страна надхвърли със своя твърд залог.
      </2>
      <3>
        Ако противоположната страна потвърди тази твърда оферта, наддаването ще се счита за успешно и това ще инициира създаването на договор.
      </3>
    `,
  },
  TradeList: {
    tooltips: {
      lastMessageTime: 'Последно съобщение',
      messagesCount: 'Общо брой съобщения',
      unreadCount: 'Непрочетени съобщения',
    },
  },
};
